import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { get } from 'lodash';
import { graphql } from 'gatsby';

import Header from '../components/Header';
import HtmlSection from '../components/sections/HtmlSection';
import AttachmentsSection from '../components/sections/Attachments';
import Layout from '../components/Layout';

const TIMESTAMP_REGEX = /(?!\d{6})_(.*)/;

const stripTimstampFromFileName = fileInfo => {
  const matches = fileInfo.name.match(TIMESTAMP_REGEX);
  if (matches) {
    console.log('matches', matches);
    return { ...fileInfo, name: matches[matches.length - 1] };
  }
  return fileInfo;
};

class MarkdownOnly extends React.Component {
  render() {
    const post = this.props.data.markdownRemark;
    const siteTitle = get(this.props, 'data.site.siteMetadata.title');

    const attachments =
      get(this.props, 'data.markdownRemark.frontmatter.attachments') || [];

    const transformedAttachments = attachments.map(stripTimstampFromFileName);

    return (
      <Layout>
        <Helmet
          title={`Mannschaft :: ${post.frontmatter.title} | ${siteTitle}`}
        />
        <Header title={post.frontmatter.title} />
        <HtmlSection html={post.html} />
        {attachments.length > 0 && (
          <AttachmentsSection attachments={transformedAttachments} />
        )}
      </Layout>
    );
  }
}

MarkdownOnly.propTypes = {
  data: PropTypes.object,
};

export default MarkdownOnly;

export const pageQuery = graphql`
  query MarkdownOnly($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        order
        attachments {
          name
          publicURL
        }
      }
    }
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import Section from '../Section';

import styles from './AttachmentsSection.module.css';

import pdfImage from './pdf.png';

const AttachmentsSection = ({ attachments }) => {
  return (
    <Section inverse>
      <article className={styles.Attachments}>
        {attachments.map(attachment => (
          <a
            className={styles.AttachmentLink}
            key={attachment.publicURL}
            href={attachment.publicURL}
          >
            <img className={styles.Extension} src={pdfImage} alt="file type" />
            <br />
            {attachment.name}
          </a>
        ))}
      </article>
    </Section>
  );
};

AttachmentsSection.propTypes = {
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      publicURL: PropTypes.string,
      name: PropTypes.string,
    })
  ),
};

export default AttachmentsSection;

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Section from '../Section';
import styles from './HtmlSection.module.css';

const HtmlSection = ({ html }) => {
  return (
    <Section
      className={cx(
        styles.addSpacingToParagraphs,
        styles.useTextAlignLeftForHeadlines
      )}
    >
      <article dangerouslySetInnerHTML={{ __html: html }} />
    </Section>
  );
};

HtmlSection.propTypes = {
  html: PropTypes.string,
};

export default HtmlSection;
